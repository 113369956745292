 
.mobile_barContainer {
  float: left;
  margin: auto,0; 
  top: 0px;
  /* position: fixed; */
  display: inline-flex;
  background-color: rgb(255, 255, 255);
  overflow-wrap: none;
  list-style: none; 
  box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
  width: 100%;
  height: 55px;
  vertical-align: baseline;
   
}

.mobile_Hamburger_menu_img {
  height: 45px; 
  padding-top: 9px;
  padding-right: 5px;
}