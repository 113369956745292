
 
.bar {
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  min-width: 700px; 
}

.bar_list_item { 
  padding-left: 5px;
  padding-right: 5px;
}

.bar_list_item_logo { 
  position:fixed;
  top: 0px;
  left: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.bar_list_item_badge { 
  position:fixed;
  right: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.bar_image_logo {
  width: 68px;
  padding-top: 3px;
}

.bar_image_pages {
  width: 100px;
  padding-top: 5px;
}

.bar_image_badges {
  width: 130px;
  padding-top: 5px;
}

.bar_image_badges_wide {
  width: 369px;
  padding-top: 5px;
}

/* img {
  width: 110px;
  padding-left: 10px;
  padding-right: 10px;
  

} */

.barContainer { 
  float: left;
  margin: auto,0; 
  top: 0px;
  /* position: fixed; */
  display: inline-flex;
  background-color: rgb(255, 255, 255);
  overflow-wrap: none;
  list-style: none; 
  box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
  width: 100%;
  min-width: 700px; 
  height: 55px;
  vertical-align: baseline;
}

.barList { 
  /* float: left; */
  padding-left: 35px;
  margin: auto,0; 
  top: 0px;

  display: inline-flex;
   
  overflow-wrap: none;
  list-style: none; 
   
  width: 100%;
   
  height: 55px;
  vertical-align: baseline;
  
}
  
/* .barImages {
  width: 110px;
  padding-left: 10px;
  padding-right: 10px;
} */
 
@media (max-width: 375px) {

  /* need hambrger! */

  .barText {
    margin: auto,0; 
    top: 0px;
    position: fixed;
    display: flex;
    background-color: rgb(235, 234, 232);
    font-family: Geneva,Tahoma,Verdana,sans-serif;   
    font-size: 0.75rem;
    color: rgb(102, 91, 78);
    padding-top: 10px; 
    padding-left: 5px; 
    padding-right: 25px; 
    overflow-wrap: wrap;
    list-style: none; 
    box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
    width: 100%;
  }

  .bar {
    position: fixed;
    top:0px;
    left:0px;
    /* background-color: rgb(155, 150, 150); */
    height: 200px;
    width: 100%;
     
  }

  .bar ul {
    margin: 0;
    padding: 0;
    overflow: wrap;
  }
  
  .bar li {
    text-align: center;
    /* float: left;
    padding-left: 20px; */
  }
 
  .bar_image_badges {
    height: 55px;
    padding-top: 5px;
  }

}