 

/*------- Mobile Specific ----------*/
 
.mobile_App_container {
  display: block;
}

.mobile_dual_item_container {
  display: inline;
}
  
.mobile_container {
  display: block;
  width: 100%;
   
  align-items: center;
  margin: 0; 
   
  text-align: center; 
  
}

.mobile_container ul{
  margin: auto; 
  width: 100%;
  text-align: center; 
}

.mobile_container li{
  
  margin: auto; 
  width: 100%;
  height: auto;
  padding: 0px;
 
}

 

.sideways_Mobile_List {
  display: inline-flex;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
   
}

.sideways_Mobile_List li {
   /* padding-bottom: 15px; */
}

.mobile_search_input {
  width: 300px;
  height: 35px;
  border-radius: 5px;
  background-color: rgb(247, 251, 255); 
  border-color: rgb(154, 186, 228, 0.2);
  cursor: pointer;

  /* margin-right: 55px; */
  padding-right: 15px;
  /* margin-right: 20px; */
}

.mobile_search_button {
  font-size: 1.4em;
  font-family: "Arial Rounded MT Bold",sans-serif;
  color:rgb(255, 156, 50);
  width: 130px;
  height: 35px;
  border-radius: 5px;
  background-color: rgb(255, 248, 228); 
  border-color: rgb(154, 186, 228, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
  /* margin-left: 4px; */
  
  cursor: pointer;
}


.mobile_list_item { 
  position: flex;
  margin-left: 10px;
  padding-bottom: 20px;
}



/*------- Mobile Aspect Ratio Specifics ----------*/


/* phone 5 portrait */ 
@media screen and (max-width: 360px) and (orientation:portrait){


  .entrance_top_image_container img{
    content:url('./images/GG_Mobile_Top.png');
    
  }


.entranceText {
  color: rgb(22,154,218);
  font-family: "Arial Rounded MT Bold",sans-serif;
  line-height: 100%;
  padding-left: 10px;
  padding-right: 10px;
   
}


  .category_header_img{

    width: 100%;
    
  }
   
  .text_Apps, .text_Shop {
   
    margin-left: 10%;
    margin-right: 10%;
      
  }

   
  .focus_exit_img {
    vertical-align: bottom;
    height: 70px;
  }

  .focus_main_image {
    zoom: 0.85;
  
  }
  
  .entrance_list {
    
    padding-top: 30px;
    
  }

  /* mobile grid stuff */
  .mobile_grid_Shop_container {
  
    display: inline-block;  
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    width: 70%;

  }

  .mobile_grid_Apps_container {
   
    margin: 0 auto;
    display: inline;
    justify-content: space-around;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 5px;
    list-style: none;
    padding: 5px;
    /* width: 70%; */

  }

  /* app containers */
  .mobile_dual_item_container img{
     
    width: 155px;
  }

  /* product containers */
  .mobile_item_container img {

    border-radius: 20px;
    width: 185px;
     
    height: auto;
    text-decoration: none;
  }

  .textSmaller {
    font-size:2.5em;

  }


  .focus_list .focus_alt_img_container {
    position: absolute;
    top: 60px;
    right: 15px;
   
  }

  .focus_BG_Container {
    position: fixed;
    top: 20px;
    left: 0px;
    background-color:rgba(157, 205, 245, 0.35);
    width: 100%;
    height: 100%;
    margin: 0, auto;
    zoom: 0.45;
  
  }

  .focus_Content_Container {
   
    margin-top: 120px;
    display: inline-block;
    text-align: center;
    background-color:rgb(255, 255, 255);
    width: 660px;
    height: auto;
    box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
    border-radius: 20px;
  }


  .focus_amazon_img {
    vertical-align: middle;
    height: 80px;
  }

  .text_App_info_container {
    /* font-size:2.5em; */
    margin: auto;
    width: 90%;
    font-size: 0.7em;
  }
  
  .entrance_Nav_container {
    line-height: 100%;
  }

  .entrance_Nav_container img {
    /* padding: 12px; */
    width: 38%;
 
  }

  .textInside {

    font-size: 1.5em;
    margin-left: 15px;
    margin-right: 15px;
    line-height: 125%;
    
  }

}

/* phone 5 landscape */ 
@media screen and (max-height: 360px) and (orientation:landscape){

  .entrance_top_image_container img{
    
    
  }



  .category_header_img{

    width: 70%;
  
  }

  .focus_exit_img {
    vertical-align: bottom;
    height: 100px;
  }


  .focus_main_image {
    zoom: 0.85;
  
  }
 

  .textSmaller {
    color: rgb(22,154,218);
    font-family: "Arial Rounded MT Bold",sans-serif;
    /* font-family: 'book antiqua','palatino linotype',serif; */
    margin-left: 110px;
    margin-right: 110px;
    line-height: 120%;
  }


  /* mobile grid stuff */
  .mobile_grid_Shop_container {

    display: inline-block;  
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    width: 70%;

  }

  .mobile_grid_Apps_container {
   
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    /* width: 70%; */

  }

  /* app containers */
  .mobile_dual_item_container img{
     
    width: 125px;
  }

  /* product containers */
  .mobile_item_container img {

    border-radius: 20px;
    width: 175px;
     
    height: auto;
    text-decoration: none;
  }

.focus_BG_Container {
  position: fixed;
  top: 25px;
  left: 0px;
  background-color:rgba(157, 205, 245, 0.35);
  width: 100%;
  height: 100%;
  margin: 0, auto;
  zoom: 0.40;

}

.focus_Content_Container {
   
  margin-top: 120px;
  display: inline-block;
  text-align: center;
  background-color:rgb(255, 255, 255);
  width: 1300px;
  height: 650px;
  box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
  border-radius: 20px;
  margin-left: 0px;
  margin-right: 0px;
}


.focus_amazon_img {
  vertical-align: middle;
  height: 80px;
}

.text_App_info_container {
  font-size:1.05em;
  margin:auto;
  width: 100%;
}
 
.text_Apps, .text_Shop {
   
  margin-left: 10%;
  margin-right: 10%;
    
}

 

}
  

/* phone portrait (min-height: 660px) and */ 
@media screen and (min-width: 361px) and
                  (max-width: 480px) and
                  (orientation:portrait){

   
  .entrance_top_image_container img{
    content:url('./images/GG_Mobile_Top.png');
  }


.entranceText {
  color: rgb(22,154,218);
  font-family: "Arial Rounded MT Bold",sans-serif;
  line-height: 100%;
  padding-left: 10px;
  padding-right: 10px;
   
}

  .category_header_img{

    width: 100%;

  }
  
  .focus_exit_img {
    vertical-align: bottom;
    height: 60px;
  }
  
  .entrance_list {
    
    padding-top: 30px;
    
  }

  /* mobile grid stuff */
  .mobile_grid_Shop_container {
  
    display: inline-block;  
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    width: 70%;

  }

  .mobile_grid_Apps_container {
   
    margin: 0 auto;
    display: inline;
    justify-content: space-around;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    /* width: 70%; */

  }

  /* app containers */
  .mobile_dual_item_container img{
     
    width: 185px;
  }

  /* product containers */
  .mobile_item_container img {

    border-radius: 20px;
    width: 175px;
     
    height: auto;
    text-decoration: none;
  }

  .textSmaller {
    font-size:2.5em;

  }

  .focus_list .focus_alt_img_container {
    position: absolute;
    top: 95px;
    right: 15px;
   
  }

  .focus_BG_Container {
    position: fixed;
    top: 20px;
    left: 0px;
    background-color:rgba(157, 205, 245, 0.35);
    width: 100%;
    height: 100%;
    margin: 0, auto;
    zoom: 0.45;
  
  }

  .focus_Content_Container {
   
    margin-top: 120px;
    display: inline-block;
    text-align: center;
    background-color:rgb(255, 255, 255);
    width: 800px;
    height: auto;
    box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
    border-radius: 20px;
  }


  .focus_amazon_img {
    vertical-align: middle;
    height: 80px;
  }

  .text_App_info_container {
    font-size:0.92em;
    margin: auto;
    width: 90%;
  }
  
  .entrance_Nav_container {
    line-height: 100%;
  }

  .entrance_Nav_container img {
    /* padding: 12px; */
    width: 38%;
 
  }

  .textInside {

    font-size: 1.5em;
    margin-left: 15px;
    margin-right: 15px;
    line-height: 125%;
    
  }

   
  .text_Apps, .text_Shop {
   
    margin-left: 10%;
    margin-right: 10%;
      
  }


}


/* phone landscape (min-height: 361px) and */ 
@media screen and (min-width: 660px) and 
                  (max-height: 450px) and
                  (orientation:landscape){

    .entrance_top_image_container img{
       
      
    }

    .category_header_img{

      width: 60%;
    
    }

    .focus_exit_img {
      vertical-align: bottom;
      height: 100px;
    }


    .focus_list .focus_alt_img_container {
      position: absolute;
      top: 5px;
      right: 15px;
   
    }
  
    .textSmaller {
      color: rgb(22,154,218);
      font-family: "Arial Rounded MT Bold",sans-serif;
      margin-left: 100px;
      margin-right: 100px;
      line-height: 120%;
    }


    /* mobile grid stuff */
    .mobile_grid_Shop_container {
  
      display: inline-block;  
      margin: 0 auto;
      display: grid;
      justify-content: space-around;
      grid-template-columns: auto auto auto;
      grid-template-rows: auto;
      grid-row-gap: 10px;
      grid-column-gap: 10px;
      list-style: none;
      padding: 10px;
      width: 70%;
  
    }
  
    .mobile_grid_Apps_container {
     
      margin: 0 auto;
      display: grid;
      justify-content: space-around;
      grid-template-columns: auto auto;
      grid-template-rows: auto;
      grid-row-gap: 10px;
      grid-column-gap: 10px;
      list-style: none;
      padding: 10px;
      /* width: 70%; */
  
    }
  
    /* app containers */
    .mobile_dual_item_container img{
       
      width: 155px;
    }
  
    /* product containers */
    .mobile_item_container img {
  
      border-radius: 20px;
      width: 175px;
       
      height: auto;
      text-decoration: none;
    }

    .focus_BG_Container {
    position: fixed;
    top: 55px;
    left: 0px;
    background-color:rgba(157, 205, 245, 0.35);
    width: 100%;
    height: 100%;
    margin: 0, auto;
    zoom: 0.40;
    padding: 5px;
  
    }

    .focus_Content_Container {
   
      margin-top: 120px;
      display: inline-block;
      text-align: center;
      background-color:rgb(255, 255, 255);
      width: 1500px;
      height: 700px;
      box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
      border-radius: 20px;
      margin-left: 0px;
      margin-right: 0px;
    }


    .focus_amazon_img {
      vertical-align: middle;
      height: 80px;
    }

    .text_App_info_container {
      font-size:1.5em;
      margin: auto;
      width: 100%;
    }

     
  .text_Apps, .text_Shop {
   
    margin-left: 20%;
    margin-right: 20%;
      
  }

 
}

/* tablet portrait */ 
@media screen and (min-width: 600px) and
                  (min-height: 700px) and 
                  (max-width: 800px) and
                  (orientation:portrait){

  .entrance_top_image_container img{
     
    
  }


.entranceText {
  color: rgb(22,154,218);
  font-family: "Arial Rounded MT Bold",sans-serif;
  line-height: 100%;
  padding-left: 10px;
  padding-right: 10px;
   
}
  
  .category_header_img{

    width: 70%;
  
  }

  .focus_main_image {
    zoom: 0.85;
  
  }

  .textSmaller {   
    color: rgb(22,154,218);
    font-family: "Arial Rounded MT Bold",sans-serif;
    margin-left: 40px;
    margin-right: 105px;
    line-height: 120%;
  }

  .focus_list .focus_alt_img_container {
    position: absolute;
    top: 75px;
    right: 15px;
   
  }

  .focus_BG_Container {
    position: fixed;
    top: 10px;
    left: 0px;
    background-color:rgba(157, 205, 245, 0.35);
    width: 100%;
    height: 100%;
    margin: 0, auto;
    zoom: 0.90;
  
  }

  .focus_Content_Container {
   
    margin-top: 70px;
    display: inline-block;
    text-align: center;
    background-color:rgb(255, 255, 255);
    width: 760px;
    height: auto;
    box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
    border-radius: 20px;
  }


  .focus_amazon_img {
    vertical-align: middle;
    height: 50px;
  }

  .text_App_info_container {
    /* font-size:2.5em; */
    margin: auto;
    width: 100%;
    font-size: 1.5em;
  }

  /* mobile grid stuff */
  .mobile_grid_Shop_container {
  
    display: inline-block;  
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    width: 70%;

  }

  .mobile_grid_Apps_container {
   
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    /* width: 70%; */

  }

  /* app containers */
  .mobile_dual_item_container img{
       
    width: 155px;
  }

  /* product containers */
  .mobile_item_container img {

    border-radius: 20px;
    width: 175px;
     
    height: auto;
    text-decoration: none;
  }
 

  .entrance_Container {
    display: block;
    padding-top: 50px;
    min-height: 70%;
    height: 100%;
  }
  
  .entrance_Nav_container {
    width: 100%;
    display:block;
    align-content: center;
     
  }
  
  .entrance_Nav_container img{
     /* padding: 12px; */
     width: 150px;
  
  }
  
  .item_title {
    margin: auto;
    margin-top: 10px;
    text-align: center;
    color: rgb(22,154,218);
    font-family: "Arial Rounded MT Bold",sans-serif;
    font-size: 1.3em;
    padding-bottom: 10px;
  }
 
    
  .text_Apps, .text_Shop {
    
    margin-left: 25%;
    margin-right: 25%;
      
  }
 

}
  
/* tablet landscape */
@media screen and (min-width: 700px) and
                  (min-height: 600px) and
                  (max-height: 800px) and
                  (max-width: 1024px) and
                  (orientation:landscape){

  .entrance_top_image_container img{
     
    
  }                  

  .category_header_img{

    width: 35%;
  
  }

  .focus_BG_Container {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color:rgba(157, 205, 245, 0.35);
    width: 100%;
    height: 100%;
    margin: 0, auto;
    zoom: 0.90;
  
  }

  .focus_Content_Container {
   
    margin-top: 70px;
    display: inline-block;
    text-align: center;
    background-color:rgb(255, 255, 255);
    width: 760px;
    height: auto;
    box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
    border-radius: 20px;
  }


  .focus_amazon_img {
    vertical-align: middle;
    height: 50px;
  }

  .textSmaller { 
    color: rgb(22,154,218);
    font-family: "Arial Rounded MT Bold",sans-serif;
    margin-left: 40px;
    margin-right: 105px;
    line-height: 120%;
  }

  .text_App_info_container {
    /* font-size:2.5em; */
    margin: auto;
    width: 100%;
    font-size: 1.2em;
  }

  /* mobile grid stuff */
  .mobile_grid_Shop_container {
  
    display: inline-block;  
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    width: 70%;

  }

  .mobile_grid_Apps_container {
   
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    /* width: 70%; */

  }

  /* app containers */
  .mobile_dual_item_container img{
       
    width: 155px;
  }

  /* product containers */
  .mobile_item_container img {

    border-radius: 20px;
    width: 175px;
     
    height: auto;
    text-decoration: none;
  }

  .text_App_info_container {
    width: 100%;
  }

  .entrance_Container {
    display: block;
    padding-top: 50px;
    min-height: 70%;
    height: 100%;
  }
  
  
  .entrance_Nav_container img {
     /* padding: 12px; */
     width: 150px;
  
  }
  
  .item_title {
    margin: auto;
    margin-top: 10px;
    text-align: center;
    color: rgb(22,154,218);
    font-family: "Arial Rounded MT Bold",sans-serif;
    font-size: 1.3em;
    padding-bottom: 10px;
  }
 
     
  .text_Apps, .text_Shop {
    
    margin-left: 25%;
    margin-right: 25%;
      
  }
 

}

/* tablet Portrait iPad Pro */
@media screen and (min-width: 1024px) and
                  (max-width: 1024px) and      
                  (min-height: 1366px) and
                  (max-height: 1366px) and
                  (orientation:portrait){
  
  
  .entranceText {
    color: rgb(22,154,218);
    font-family: "Arial Rounded MT Bold",sans-serif;
    line-height: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }


  .category_header_img{

    width: 25%;
  
  }

  .focus_BG_Container {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color:rgba(157, 205, 245, 0.35);
    width: 100%;
    height: 100%;
    margin: 0, auto;
    zoom: 0.90;
  
  }

  .focus_Content_Container {
   
    margin-top: 70px;
    display: inline-block;
    text-align: center;
    background-color:rgb(255, 255, 255);
    width: 760px;
    height: auto;
    box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
    border-radius: 20px;
  }


  .focus_amazon_img {
    vertical-align: middle;
    height: 50px;
  }

  .textSmaller {
    color: rgb(22,154,218);
    font-family: "Arial Rounded MT Bold",sans-serif;
    margin-left: 40px;
    margin-right: 105px;
    line-height: 120%;
  }

  .text_App_info_container {
    /* font-size:2.5em; */
    margin: auto;
    width: 100%;
    font-size: 1.2em;
  }

  /* mobile grid stuff */
  .mobile_grid_Shop_container {
  
    display: inline-block;  
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    width: 70%;

  }

  .mobile_grid_Apps_container {
   
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    /* width: 70%; */

  }

  /* app containers */
  .mobile_dual_item_container img{
       
    width: 155px;
  }

  /* product containers */
  .mobile_item_container img {

    border-radius: 20px;
    width: 175px;
     
    height: auto;
    text-decoration: none;
  }

  .text_App_info_container {
    width: 100%;
  }

  .entrance_Container {
    display: block;
    padding-top: 50px;
    min-height: 70%;
    height: 100%;
  }
  
  
  .entrance_Nav_container img {
     /* padding: 12px; */
     width: 150px;
  
  }
  
  .item_title {
    margin: auto;
    margin-top: 10px;
    text-align: center;
    color: rgb(22,154,218);
    font-family: "Arial Rounded MT Bold",sans-serif;
    font-size: 1.3em;
    padding-bottom: 10px;
  }
 
     
  .text_Apps, .text_Shop {
    
    margin-left: 25%;
    margin-right: 25%;
      
  }
 

}

/* tablet landscape iPad Pro */
@media screen and (min-width: 1366px) and
                  (max-width: 1366px) and      
                  (min-height: 1024px) and
                  (max-height: 1024px) and
                  (orientation:landscape){
  
  .category_header_img{

    width: 25%;
  
  }

  .focus_BG_Container {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color:rgba(157, 205, 245, 0.35);
    width: 100%;
    height: 100%;
    margin: 0, auto;
    zoom: 0.90;
  
  }

  .focus_Content_Container {
   
    margin-top: 70px;
    display: inline-block;
    text-align: center;
    background-color:rgb(255, 255, 255);
    width: 760px;
    height: auto;
    box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
    border-radius: 20px;
  }


  .focus_amazon_img {
    vertical-align: middle;
    height: 50px;
  }

  .textSmaller {
    color: rgb(22,154,218);
    font-family: "Arial Rounded MT Bold",sans-serif;
    margin-left: 40px;
    margin-right: 105px;
    line-height: 120%;
  }

  .text_App_info_container {
    /* font-size:2.5em; */
    margin: auto;
    width: 100%;
    font-size: 1.2em;
  }

  /* mobile grid stuff */
  .mobile_grid_Shop_container {
  
    display: inline-block;  
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    width: 70%;

  }

  .mobile_grid_Apps_container {
   
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    list-style: none;
    padding: 10px;
    /* width: 70%; */

  }

  /* app containers */
  .mobile_dual_item_container img{
       
    width: 155px;
  }

  /* product containers */
  .mobile_item_container img {

    border-radius: 20px;
    width: 175px;
     
    height: auto;
    text-decoration: none;
  }

  .text_App_info_container {
    width: 100%;
  }

  .entrance_Container {
    display: block;
    padding-top: 50px;
    min-height: 70%;
    height: 100%;
  }
  
  
  .entrance_Nav_container img {
     /* padding: 12px; */
     width: 150px;
  
  }
  
  .item_title {
    margin: auto;
    margin-top: 10px;
    text-align: center;
    color: rgb(22,154,218);
    font-family: "Arial Rounded MT Bold",sans-serif;
    font-size: 1.3em;
    padding-bottom: 10px;
  }
 
     
  .text_Apps, .text_Shop {
    
    margin-left: 25%;
    margin-right: 25%;
      
  }
 

}
