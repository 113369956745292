* {
  box-sizing: border-box;
  
}

html {
  scroll-behavior: smooth;
}
 
ul {

  list-style-type: none;
  list-style: none;
 
}

li {
  float: middle;
  padding-left: 0px
}


.App {
  /* position: absolute; */
  display: flex;
  text-align: center; 
  margin: 0 auto;
  width: stretch;
  background-color: #ffffff;
  /* font-size: 1.0em; */
  
}

.category_header {

  padding-top: 0px;
  padding-bottom: 0px;
  

}

.category_header_ul {
  padding-left: 0px;
}
  
.category_header_img {

  width: 35%;

}



.center {
  margin: auto;
  padding: 0px;
  width: 90%;
  padding: 10px;
}

.entrance_Container {
  display: block;
  align-content: center;
  margin: auto, 0;
  text-align: center;
  padding-top: 50px;
  min-height: 70%;
  width: 100%;
  height: 100%;
  overflow: clip;
}
 
 
.entrance_top_image_container img{ 
  content:url('./images/Big_top2.png');
  width: 100%;
  max-width: 1024px;
   
}
 
.entrance_Nav_container {
  
  display:inline;
  align-content: center;
   
}

.entrance_Nav_container img{
   /* padding: 25px; */
   /* min-width: 10%;
   max-width: 15%; */
   padding-left: 12px;
   padding-right: 12px;
   padding-bottom: unset;
   width: 220px;
}

.entranceText {
  color: rgb(22,154,218);
  font-family: "Arial Rounded MT Bold",sans-serif;
  line-height: 110%;
  padding-left: 30%;
  padding-right: 30%;
   
}


.focus_BG_Container {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color:rgba(157, 205, 245, 0.35);
  width: 100%;
  height: 100%;
  margin: 0, auto;
  zoom: 1.0;
  
}
 

.focus_Content_Container {
  margin-top: 120px;
  display: inline-block;
  text-align: center;
  background-color:rgb(255, 255, 255);
  width: 800px;
  box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
  border-radius: 20px;
}

/* .focus_Inner_Container {
  
  padding-top: 0px;
  display: inline-block;
  text-align: center;
   
}  */

.focus_list {
  top:0px;
  position: relative;
  margin: 0, auto;
   
}

.focus_list .focus_alt_img_container {
  position: absolute;
  top: 50px;
  right: 15px;
   
}

.focus_list .focus_exit_img_container {
  position: absolute;
  top: 50px;
}
 
.focus_alt_img {
  vertical-align: bottom;
  height: 50px;
  border-style: solid; 
  border-width:2px; 
  border-color: rgba(255, 153, 0, 0.35);
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  width: 80px;
  height: 70px;
}

.focus_exit_img {
  vertical-align: bottom;
  height: 50px;
}

.focus_amazon_img {
  vertical-align: middle;
  height: 40px;
}




.menu_Container {

  position: fixed;
  top: -20px;
  right: -20px;
  width: 240px;
  height: auto;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 15px 15px rgba(11, 32, 65, 0.5); 
  border-radius: 15px; 
}

.menu_Container ul {

  margin-top: 50px;
  margin-left:-12px;
   
  
  list-style: none;
  width: 100%; 
  padding-bottom: 25px;
  padding-top: 25px;

}

.menu_Container li {
  
  text-align: center;
  color: rgb(22,154,218);
  font-family: "Arial Rounded MT Bold",sans-serif;
  font-size: 1.1em;
  padding-left: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(255, 255, 255);
  border-color: rgba(0, 88, 204, 0.111);
  border-style: solid;
  border-radius: 5px;
  text-decoration: none; 
}

.menu_image_links {

  height: 35px;
  padding-top: 5px;

}
 
.menu_image_badges {
  height: 45px;
  padding-top: 5px;
}

.menu_Exit {

  position: fixed;
  top: 0px;
  right: 0px;
  height: 45px; 
  padding-right: 10px;
}


.textContainer {
  margin: 0, auto;
  padding-top: 20px;
  display: inline-block;
  text-align: center;
}

.textInside {

  color: rgb(22,154,218);
  font-family: "Arial Rounded MT Bold",sans-serif;
  /* font-family: 'Roboto Slab', serif; */
   
  line-height: 110%;
}
 
.text_Apps {
  
  color: rgb(22,154,218);
  font-family: "Arial Rounded MT Bold",sans-serif;
  /* font-family: 'book antiqua','palatino linotype',serif; */
  line-height: 120%;
  margin-left: 30%;
  margin-right: 30%;
   
}

.text_Shop {
   
  color: rgb(255, 115, 0);
  font-family: "Arial Rounded MT Bold",sans-serif;
  /* font-family: 'book antiqua','palatino linotype',serif; */
  line-height: 120%;
  margin-left: 30%;
  margin-right: 30%;
  
}

.text_product_Shop {
  color: rgb(22,154,218);
  font-family: "Arial Rounded MT Bold",sans-serif;
  /* font-family: 'book antiqua','palatino linotype',serif; */
  margin-left: 155px;
  margin-right: 155px;
  line-height: 120%;
}



.textSmaller {
  color: rgb(22,154,218);
  font-family: "Arial Rounded MT Bold",sans-serif;
  /* font-family: 'book antiqua','palatino linotype',serif; */
  margin-left: 65px;
  margin-right: 65px;
  line-height: 120%;
}

.text_App_info_container {
  margin: auto;
  width: 87%;
}

.grid-container {
  
  display: inline-block;  
  margin: 0 auto;
  display: grid;
  justify-content: space-around;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  list-style: none;
  padding: 10px;
  width: 70%;
  padding-top: 30px;

}

.search_container { 
  margin: auto, 0;  
  overflow-wrap: none;
  list-style: none; 
  width: 100%;
  vertical-align: baseline;
  border-color: rgb(154, 186, 228, 0.2);
}

.search_input {

  width: 300px;
  height: 35px;
  border-radius: 5px;
  background-color: rgb(247, 251, 255); 
  border-color: rgb(154, 186, 228, 0.2);
  cursor: pointer;
  padding-right: 15px;
}


.search_label {
  font-size: 1.4em;
  font-family: "Arial Rounded MT Bold",sans-serif;
  color: rgb(22,154,218);
  margin-right: 4px;
}

.search_button {
  font-size: 1.4em;
  font-family: "Arial Rounded MT Bold",sans-serif;
  color:rgb(255, 156, 50);
  width: 130px;
  height: 35px;
  border-radius: 5px;
  background-color: rgb(255, 248, 228); 
  border-color: rgb(154, 186, 228, 0.2);
  margin-left: 0px;
  
  cursor: pointer;
}

.search_message {
  font-size: 1.5em;
  font-family: "Arial Rounded MT Bold",sans-serif;
  color: rgb(22,154,218);
  margin-top: 2px;
}

input[type=text], select {

  font-size: 1.4em;
  font-family: "Arial Rounded MT Bold",sans-serif;
  color:rgb(255, 137, 1);

}

::placeholder { 
   
  font-family: "Arial Rounded MT Bold",sans-serif;
  color:rgb(196, 206, 221);

}
 

.grid-container li {
  float: left;
  padding: 10px;
}
 


a {
  text-decoration: none;
  color: 'orange'
}


.image_info {
  /* position: absolute;
  text-justify:  */
  /* top:20px; */
  /* margin: auto,0; */
}


em {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  position: absolute;
  bottom: 75px;
  left: 16%;
  right: 16%;
  background:rgba(255, 149, 87, 0.55);
  color: hsl(0, 0%, 100%);
  padding: 7px;
  font-weight: bold;
  font-style: normal;
  border-radius: 6px; 
}

.special {
  width: auto;

}

.grid_Images {
  /* width: 10%; */
  box-shadow: 0 0 5px rgba(0.2,0.2,0.2,0.2); 
}

.grid_Images_transparent {
  opacity: 0.10;
   
}

.grid_Images_zoom {
  position: fixed;
  top: 12%;
  /* left: 30%; */
  width: 30%;
  height: auto;
  opacity: 1.0;
  /* padding: 10px; */
  box-shadow: 0 0 5px rgba(0.2,0.2,0.2,0.2); 
}


.item_text {
  position: relative;
  top: 50%;
   
  padding: 10px;
  background-color:rgba(255, 149, 87, 0.55);
  color: black;
  opacity: 1.0;
  font-size: auto;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

.grid_hover {

  box-shadow: 0 0 5px rgba(0.2,0.2,0.2,0.2);

}
 

.item_container img{
  border-radius: 20px;
  width: 180px;
  height: auto;
  text-decoration: none;
}


.item_title_container {
  width: 180px;
  padding-top: 0px;
  padding-bottom: 15px;
  margin: auto;
  text-align: center;
}

.item_title {
  margin: auto;
  margin-top: 10px;
  text-align: center;
  color: rgb(22,154,218);
  font-family: "Arial Rounded MT Bold",sans-serif;
  font-size: 1.2em;
   
}
  
.reg_images {
  width: 10px;

}
 
.white_out {
  background-color: white;
  opacity: 0.50;
  position: fixed;
  top: 0px;
  left: 0px;
  width: stretch;
}

.alter_gridImages {
  width: 500px;
}

.about_paragraph {
  color:gray;
  /* text-align:left; */
  width: 300px;
}


.intro_container {
  text-align: center;
  width: 100%;
   
}

.li_gallery_grid_container {

  width: 100%

}

.dashboard_List {
  margin: auto;
  padding: 0px;
  text-align: center;
  width: 100%;
   
}

.sideways_List li {
  display: block;
  width: 30%; 
  text-align: center;
   
  padding: 30px;
}

.sideways_List {
  display: inline-flex;
  text-align: center;
  width: 100%;
  list-style-type: none;
  margin-left: 50px;
  margin-right: 50px;
  padding: 0;
  overflow: hidden;
   
}

.privacy_policy_container {

  margin-top: 100px;
  width: 100%;
   

}

.text_privacy_policy_main {

  font-size: 1.3em;
  font-family: "Arial Rounded MT Bold",sans-serif;
  color: rgb(22,154,218);
  margin-right: 80px;
  margin-left: 80px;
}

.text_privacy_policy {
  font-size: 0.7em;
  font-family: "Arial Rounded MT Bold",sans-serif;
  color: rgb(22,154,218);
  margin-right: 10px;
  margin-left: 10px;
}


  