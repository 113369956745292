.mover_container {
   margin: 0;
}

.mover_container li{
   
  display: inline;
   
}

 

@media screen and (min-width: 401px) and 
                  (max-height: 700px) and
                  (orientation: portrait)
{

  .shuffle_grid_container {
  
    display: inline-block;  
    margin: 0 auto;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    list-style: none;
    padding: 10px;
    width: 70%;
    padding-top: 30px;
  
  }
 

}


  
  